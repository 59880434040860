import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BasicContentPage from "../components/reusable-components/basic-content-page"

const Service = ({ location, pathContext }) => {
  const servicePageQueryData = useStaticQuery(SERVICE_PAGE_QUERY)

  return (
    <BasicContentPage
      pageContent={servicePageQueryData}
      pathContext={pathContext}
      location={location}
    />
  )
}

export default Service

// ===============
//      QUERY
// ===============
const SERVICE_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "service" }) {
      content
    }
    seaPage(slug: { eq: "service" }) {
      seo {
        description
        meta_description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
